@import "../../../mixins.scss";

.button {
  display: none;

  // Should be updated with styles for red buttons.
  @include breakpoint(mobile) {
    border: none;
    text-decoration: none;
    margin: auto;
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
    padding: 16px 12px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    background: var(--accentGreen);
    color: var(--grey8);

    &.fullWidth {
      padding: 24px;
      border-radius: 0;
    }

    &:disabled {
      color: var(--grey50);
      background-color: var(--grey80);
    }

    @include darkTheme {
      &:disabled {
        color: var(--grey8);
        background-color: var(--grey30);
      }
    }
  }
}
