@import "@edgedb/common/mixins.scss";

.header {
  flex-shrink: 0;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 16px 0 19px;

  .themeSwitcher {
    margin-left: auto;
    z-index: 110;
  }
}

.subtitle {
  display: flex;
  font-family: "EdgeDB";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  color: #848484;
  margin-left: 8px;

  @include darkTheme {
    color: #7d7d7d;
  }
}

.logo {
  fill: #4f4f4f;

  g {
    fill: #848484;
  }

  @include darkTheme {
    fill: #f7f7f7;
    g {
      fill: #c1c1c1;
    }
  }
}

.themeSwitcher {
  margin-left: auto;

  @include breakpoint(mobile) {
    display: none;
  }
}
