@import "../../mixins.scss";

.select {
  --hoverBg: var(--app-bg-gray);
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: var(--select-option-color, #6b6b6b);
  cursor: pointer;

  svg {
    color: var(--svg-color, --select-option-color, #6b6b6b);
    flex-shrink: 0;
  }

  &:hover {
    background: var(--hoverBg);

    .tabDropdownButton {
      background: none;
      opacity: 1;
    }
  }

  &[data-disabled="true"] {
    pointer-events: none;
    opacity: 0.6;

    .tabDropdownButton {
      display: none;
    }
  }

  .placeholder {
    opacity: 0.8;
    font-style: italic;
    white-space: nowrap;
  }

  @include darkTheme {
    --hoverBg: var(--app-bg-gray);
  }
}

.tabDropdownButton {
  padding-left: 8px;
  margin-left: auto;
  opacity: 0.8;
}

.tabDropdown {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--header-dropdown-bg);
  box-shadow: 0px 32px 20px -24px rgba(0, 0, 0, 0.12),
    0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  overflow-y: auto;
  @include hideScrollbar;
  z-index: 1;
  cursor: default;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.15s, transform 0.15s, z-index 0s 0.15s;

  @include breakpoint(mobile) {
    background-color: var(--white);

    @include darkTheme {
      background-color: var(--grey14);
    }
  }

  &.tabDropdownOpen {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    z-index: 200;
    transition: opacity 0.15s, transform 0.15s, z-index 0s;
  }

  &.rightAlign {
    right: 0;
    left: unset;
  }

  @include breakpoint(mobile) {
    &.fullScreen {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: #fff;
      color: #999;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;

      .dropdownTitle {
        color: #1a1a1a;
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }

      .closeDropdown {
        background: transparent;
        border: none;
        color: #737373;
        position: absolute;
        top: 23px;
        right: 18px;
      }

      @include darkTheme {
        background: #2e2e2e;
        border-color: #141414;

        .dropdownTitle {
          color: #b9b9b9;
        }
      }
    }
  }
}

.searchInput {
  outline: 0;
  border: 0;
  background: #f2f2f2;
  margin: 6px;
  padding: 6px 8px;
  border-radius: 3px;
  font-family: inherit;
  color: inherit;

  @include darkTheme {
    background: #1f1f1f;
  }
}

.searchFullScreen {
  display: none;

  @include breakpoint(mobile) {
    display: block;
    padding: 0 24px 12px 24px;
    position: relative;

    svg {
      position: absolute;
      left: 38px;
      top: 10px;
    }

    input {
      outline: 0;
      border: 0;
      border-radius: 6px;
      background: #f2f2f2;
      padding: 12px 14px 12px 38px;
      font-weight: 600;
      color: #7c7c7c;
      width: calc(100% - 48px);

      @include darkTheme {
        background: #141414;
      }
    }
  }
}

.dropdownItem {
  box-sizing: border-box;
  padding: 8px 12px;
  width: max-content;
  min-width: 100%;
  cursor: pointer;
  padding-right: 32px;
  color: var(--dropdown-item-color, inherit);

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover {
    background: var(--app-accent-green);
    color: #fff;

    * {
      color: inherit;
    }
  }

  &.dropdownItemSelected {
    padding-right: 8px;

    @include breakpoint(mobile) {
      padding-right: 16px;
    }
  }

  &.groupHeader {
    pointer-events: none;
    opacity: 0.7;
  }

  .searchHighlight {
    background: #1f8aed;
    border-radius: 2px;
    color: #fff;
  }

  @include breakpoint(mobile) {
    &.fullScreen {
      padding: 24px 16px 24px;
      border-top: 2px solid #d9d9d9;

      @include darkTheme {
        border-color: #141414;
      }
    }
  }

  .itemContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > svg {
      margin: 0 8px 0 12px;
    }
  }
}

.dropdownActionsGroup {
  background: var(--header-dropdown-actions-bg);

  .dropdownItem {
    padding-right: 12px;
  }
}
