@use "@sass-fairy/url";
@import "./hooks/useTheme/mixin.scss";

@mixin hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin customScrollbar {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-corner {
    width: 12px;
    height: 12px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: padding-box;
  }
}

@function dashedBorderBg($stroke, $width: 1, $radius: 8, $dash-array: "4,4") {
  @return url.svg(
    '<svg width="100%" height="100%">' '<defs><clipPath id="dashedBorder">'
      '<rect width="100%" height="100%" rx="#{$radius}" ry="#{$radius}" />'
      "</clipPath></defs>"
      '<rect width="100%" height="100%" fill="none" rx="#{$radius}" ry="#{$radius}" '
      'stroke="#{$stroke}" stroke-width="#{$width * 2}" stroke-dasharray="#{$dash-array}" '
      'clip-path="url(#dashedBorder)" ' "/>" "</svg>"
  );
}

$breakpoints: (
  mobile: 768px,
);

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Subtracts 0.5px to make breakpoint exclusive (width < x) instead of
    // inclusive (width <= x)
    // (Uses -0.5px instead of -1px due to incorrect behaviour on some
    // systems ie. win10 with fractional display scaling ¯\_(ツ)_/¯)
    @media (max-width: #{$breakpoint-value - 0.5px}) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin isMobile {
  @media (max-width: 767.5px) {
    @content;
  }
}
