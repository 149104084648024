@import "../../mixins.scss";

.switch {
  width: 28px;
  height: 16px;
  border-radius: 8px;
  margin-left: 34px;
  margin-right: 82px;
  background: #d3d3d3;
  position: relative;
  cursor: pointer;
  left: 0;

  .radio {
    position: absolute;

    input[type="radio"] {
      display: none;
    }

    .label {
      display: inline-block;
      position: relative;
      font-size: 12px;
      font-family: "Roboto";
      line-height: 14px;
      font-weight: 500;
      color: #666666;
      padding: 4px 0;
      cursor: pointer;
      bottom: 3px;
    }

    &:hover {
      cursor: pointer;
    }

    .notChecked {
      z-index: 1;
    }

    input[type="radio"]:checked + label:before {
      border-radius: 11px;
      background: #fff;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 6px;
    }

    &:nth-of-type(1) {
      right: 0;

      .label {
        span {
          margin-right: 36px;
        }
      }

      input[type="radio"] + label:before {
        content: " ";
      }

      input[type="radio"]:checked + label:before {
        right: 15px;
      }
    }

    &:nth-of-type(2) {
      left: 0;

      .label {
        span {
          margin-left: 36px;
        }
      }

      input[type="radio"] + label:before {
        content: " ";
      }

      input[type="radio"]:checked + label:before {
        left: 15px;
      }
    }
  }

  @include darkTheme {
    background: #141414;

    .radio {
      .label {
        color: #b3b3b3;
      }

      input[type="radio"]:checked + label:before {
        background: #b3b3b3;
      }
    }
  }
}

.labelsSwitch {
  display: flex;
  padding: 4px;
  background: #999;
  border-radius: 22px;
  align-items: center;
  height: 32px;

  .radio {
    input {
      display: none;
    }

    .label {
      color: #fff;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      border-radius: 16px;
      padding: 8px 10px;
    }

    .checked {
      background: #fff;
      color: #666;
    }

    @include darkTheme {
      .label {
        color: #242424;
      }

      .checked {
        background: #141414;
        color: #999;
      }
    }
  }
}
