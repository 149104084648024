@import "@edgedb/common/mixins.scss";

.previewPage {
  background-color: #f3f4f6;
  margin: 0;
  padding: 48px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-clip: padding-box;
  display: grid;
  grid-template-rows: 1fr auto;
  justify-content: center;
  justify-items: center;
  font-family: "Roboto Flex Variable", sans-serif;

  @include darkTheme {
    border-color: #484848;
  }

  @include isMobile {
    padding: 48px 32px;
    margin: 0 -32px;
    border-radius: 0;
    border-left: none;
    border-right: none;
    overflow: hidden;
  }

  a {
    cursor: pointer;
  }

  .brandLogo {
    margin-bottom: 16px;
    align-self: end;
  }
  .brandLogo img {
    max-width: 300px;
    max-height: 100px;
  }

  .wrapper {
    grid-row: 2;
  }

  form {
    background: #fff;
    padding: 24px;
    padding-bottom: 16px;
    width: 326px;
    border-radius: 16px;
    box-shadow: 0px 2px 2px rgba(3, 7, 18, 0.02),
      0px 7px 7px rgba(3, 7, 18, 0.03), 0px 16px 16px rgba(3, 7, 18, 0.05);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  form h1 {
    margin: 0;
    color: #495057;
    font-size: 22px;
    font-style: normal;
    font-weight: 550;
    margin-bottom: 20px;
  }
  form h1 span {
    opacity: 0.7;
  }

  form textarea,
  form input {
    border-radius: 8px;
    border: 1px solid #dee2e6;
    background: #f8f9fa;
    line-height: 40px;
    padding: 0 14px;
    color: #495057;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    margin-bottom: 16px;
  }

  form label {
    color: #495057;
    font-size: 16px;
    font-weight: 450;
    line-height: 18px;
    margin-bottom: 8px;
  }

  form button {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
    padding: 0 12px;
    height: 46px;
    border-radius: 8px;
    background: var(--accent-bg-color);
    border: none;
    color: var(--accent-bg-text-color);
    font-family: inherit;
    font-size: 17px;
    font-weight: 550;
    cursor: pointer;
    margin: 8px 0;

    span {
      grid-column: 2;
      margin: 0 12px;
    }

    svg {
      margin-left: 8px;
      justify-self: end;
    }

    &:hover {
      background: var(--accent-bg-hover-color);
    }

    &.secondary {
      background: none;
      border: 1px solid #ced4da;
      color: #6c757d;
      font-weight: 500;

      svg {
        color: #adb5bd;
      }

      &:hover {
        background: #f5f6f8;
      }
    }

    &.iconOnly {
      display: flex;
      width: 46px;
      padding: 0;
      justify-content: center;
      margin-right: 12px;
      flex-shrink: 0;

      svg {
        margin-left: 0;
        transform: scaleX(-1);
      }
    }
  }

  .buttonGroup {
    display: flex;

    button:not(.iconOnly) {
      flex-grow: 1;
    }
  }

  .sliderContainer {
    width: calc(100% + 48px);
    display: flex;
    align-items: flex-start;
    margin: 0 -24px;
    transition: transform 0.3s, height 0.3s;
  }

  .sliderSection {
    width: calc(100% - 48px);
    margin: 0 24px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s 0s linear, visibility 0s 0.3s linear;

    & > * {
      flex-shrink: 0;
    }

    &.active {
      height: auto;
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }
  }

  .tabs {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 20px;
  }
  .tab {
    position: relative;
    display: flex;
    height: 38px;
    align-items: center;
    padding: 0 12px;
    color: #6c757d;
    font-size: 15px;
    font-weight: 550;
    cursor: pointer;

    svg {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      fill: var(--accent-text-color);
      opacity: 0;
      transition: opacity 0.3s;
    }

    &.active {
      color: #495057;

      svg {
        opacity: 1;
      }
    }
  }

  .fieldHeader {
    display: flex;
    justify-content: space-between;
  }
  .fieldNote {
    color: #97a1ab;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
  }
  a.fieldNote:hover {
    color: var(--accent-text-color);
  }

  .oauthButtons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 8px;

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 46px;
      padding: 0 12px;
      border-radius: 8px;
      border: 1px solid #dee2e6;
      text-decoration: none;
      color: #495057;
      font-size: 16px;
      font-weight: 450;

      &:hover {
        background: #f5f6f8;
      }

      span {
        margin-left: 12px;
      }
    }

    &.collapsed {
      flex-direction: row;
      flex-wrap: wrap;

      a {
        padding: 0;
        width: 46px;
        justify-content: center;
        flex-shrink: 0;

        span {
          display: none;
        }
      }
    }
  }

  .divider {
    display: flex;
    align-items: center;
    color: #6c757d;
    font-size: 16px;
    font-weight: 450;
    line-height: 19px;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .divider span {
    margin: 0 16px;
  }
  .divider:before,
  .divider:after {
    content: "";
    height: 0;
    border-bottom: 1px solid #dee2e6;
    flex-grow: 1;
  }

  .bottomNote {
    color: #6c757d;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 4px;
  }
  .bottomNote a {
    color: var(--accent-text-color);
    text-decoration: none;
  }

  &.darkTheme {
    background-color: #191c1f;

    form {
      background: #2a2f34;

      h1 {
        color: #dee2e6;
      }

      button.secondary {
        border-color: #495057;
        color: #ced4da;

        svg {
          color: #6c757d;
        }
        &:hover {
          background: #363c42;
        }
      }
    }

    form textarea,
    form input {
      border-color: #495057;
      background: #31373d;
      color: #dee2e6;
    }

    form label {
      color: #dee2e6;
    }

    .fieldNote {
      color: #adb5bd;
    }
    a.fieldNote:hover {
      color: var(--accent-text-dark-color);
    }

    .oauthButtons a {
      border-color: #495057;
      color: #dee2e6;
    }
    .oauthButtons a:hover {
      background: #363c42;
    }

    .divider {
      color: #6c757d;
    }
    .divider:before,
    .divider:after {
      border-bottom-color: #495057;
    }

    .tab {
      color: #adb5bd;

      &.active {
        color: #dee2e6;
      }
    }

    .bottomNote {
      color: #ced4da;
    }
    .bottomNote a {
      color: var(--accent-text-dark-color);
    }
  }
}
