.debugopts {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  grid-gap: 8px;
  padding: 10px;
}
