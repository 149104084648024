.main {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.loading {
  opacity: 0.5;
  font-style: italic;
}
