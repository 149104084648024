@import "@edgedb/common/mixins.scss";

.splitViewContainer {
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
  align-items: stretch;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &.splitVertical {
    flex-direction: column;
  }
}

.splitViewChild {
  overflow: hidden;
  display: flex;
  min-width: 0;
  min-height: 0;
  flex-shrink: 0;
  flex-grow: 0;
  background: var(--app-panel-bg);

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @include breakpoint(mobile) {
    width: 100% !important;
    z-index: 0;

    &:last-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  .splitVertical & {
    &:first-child {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-top-right-radius: 4px;
    }
  }
}

.resizer {
  min-width: 4px;
  min-height: 4px;
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(mobile) {
    display: none;
  }

  .grabHandle {
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    cursor: ew-resize;
  }

  .resizerIndicator {
    width: 10px;
    height: 40px;
    flex-shrink: 0;
    background: var(--app-bg);
    border-radius: 5px;
    pointer-events: none;

    &:after {
      content: "";
      display: block;
      width: 2px;
      height: 32px;
      background: var(--app-card-bg);
      margin: 4px;
      border-radius: 2px;
    }

    .splitVertical & {
      height: 10px;
      width: 40px;

      &:after {
        height: 2px;
        width: 32px;
      }
    }
  }

  .resizerFlip {
    min-width: 8px;
    height: 19px;
    border-radius: 2px;
    background-color: var(--resizer-flip-background, var(--bgColour));
    color: var(--resizer-flip-icon);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.1s, min-width 0.1s;
    cursor: pointer;
    z-index: 1;

    svg {
      opacity: 0;
      transition: opacity 0.1s;
    }
  }

  .splitVertical > & {
    .grabHandle {
      cursor: ns-resize;
    }

    .resizerFlip {
      height: 8px;
      width: 19px;
    }
  }

  &:hover {
    .resizerFlip {
      height: 19px;
      min-width: 19px;
      transition-delay: 0.2s;

      svg {
        opacity: 1;
        transition-delay: 0.2s;
      }
    }
  }
}
