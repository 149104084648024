@import "../../mixins.scss";

.switcherButton {
  position: relative;
  z-index: 110;
  --buttonBg: #e6e6e6;
  --buttonTextColour: #5e5e5e;

  @include darkTheme {
    --buttonBg: #2f2f2f;
    --buttonTextColour: #cdcdcd;
  }
}

.switcherLabel {
  display: flex;
  flex-direction: column;

  :not(.selected) {
    visibility: hidden;
    height: 0;
  }
}

.popup {
  position: absolute;
  background: #fff;
  border-radius: 1rem;
  bottom: calc(100% + 10px);
  left: 50%;
  min-width: 100%;
  transform: translateX(-50%);
  padding: 0.5rem 0;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

  &:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: rotate(45deg);
    top: calc(100% - 6px);
    left: calc(50% - 6px);
    z-index: -1;
  }

  @include darkTheme {
    background: #313131;
  }
}

.item {
  padding: 1rem 2rem;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  padding-right: 36px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);

    @include darkTheme {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &.selectedItem {
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 20px 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 6L9 17L4 12' stroke='%230CCB93' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
