.button {
  padding: 8px;
  background-color: unset;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: Roboto, sans-serif;

  .inner {
    height: 24px;
    background-color: var(--buttonBg);
    border-radius: 12px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    color: var(--buttonTextColour);

    & > span {
      margin: 0 auto;
    }
  }

  .shortcut {
    text-transform: none;
    opacity: 0.8;
    margin-left: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-right: -6px;
  }

  &.leftIcon svg {
    margin-left: -6px;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.largeButton {
    .inner {
      height: 46px;
      border-radius: 23px;
      font-size: 16px;
      font-weight: bold;
      padding: 0 23px;
      text-transform: none;
    }
  }

  &.squareButton {
    .inner {
      border-radius: 4px;
    }
  }
}
