@import "@edgedb/common/mixins";

.loginPage {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.title {
  position: absolute;
  top: 16px;
  left: 24px;
}

.loginForm {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 280px;

  @include darkTheme {
    input {
      background: #282828;
    }
  }

  .loginButton {
    --buttonBg: var(--app-accent-green);
    --buttonTextColour: #fff;
    align-self: flex-end;
    padding: 0;

    & > div {
      padding: 6px 16px;
      border-radius: 18px;
      font-weight: 600;
    }

    .loginButtonIcon {
      stroke: currentColor;
      height: 14px;
    }
  }
}

.loginError {
  color: #db5246;
  background: rgba(219, 82, 70, 0.1);
  padding: 12px 16px;
  border-radius: 6px;
  position: absolute;
  top: 100%;
  margin-top: 16px;
  left: 0;
  right: 0;

  span {
    font-weight: 600;
  }
}
