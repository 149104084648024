.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 300;
  user-select: none;
  transition: pointer-events 0.3s;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--modalOverlayBg);
    backdrop-filter: blur(3px);
    transition: opacity 0.3s;
  }

  :global(.MODAL_TRANSITION) & {
    pointer-events: none;

    &:before {
      opacity: 0;
    }
  }
}

.modalCard {
  position: relative;
  background: var(--app-modal-bg);
  box-shadow: 0px 32px 20px -24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-width: 70vw;

  .modalError {
    position: absolute;
    top: calc(100% + 3rem);
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.modalTitle {
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  color: #848484;
}

.modalClose {
  color: #666;
  margin: -1rem -0.5rem -1rem auto;
  cursor: pointer;

  &:hover {
    color: #999;
  }
}

.modalContent {
  min-width: 40rem;
  color: #acacac;
  font-size: 14px;
  line-height: 24px;
  overflow: auto;

  .modalField:last-child {
    margin-bottom: 0;
  }
}

.modalProgressToggle {
  text-transform: uppercase;
  margin-top: 3rem;
  font-size: 12px;
  color: #858585;
  cursor: pointer;
}

.modalProgress {
  background: #0b0b0b;
  border-radius: 0.5rem;
  font-family: Roboto Mono, monospace;
  padding: 1rem;
  color: #a2a2a2;
  overflow: auto;
  max-width: 80ch;
  max-height: 15em;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  margin-bottom: -1rem;
}

.modalField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.5rem;

  > span {
    color: #6b6b6b;
    font-size: 14px;
    margin-bottom: 0.75rem;
  }

  input,
  select {
    background: var(--form-field-bg);
    border: none;
    outline: none;
    height: 4.75rem;
    font-family: Roboto Mono;
    font-size: 14px;
    color: var(--form-field-text-colour);
    padding: 0 1.5rem;
    border: 2px solid transparent;
    border-radius: 0.5rem;
    align-self: stretch;

    &.fieldError {
      border-color: #db5246;
    }
  }

  .select {
    background: #ebebeb;
    padding: 0 12px;
    align-self: stretch;
    height: 42px;
  }

  .errorMessage {
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: #db5246;
  }

  &.checkboxField {
    flex-direction: row;
    padding: 0 8px;

    input {
      appearance: none;
      width: 20px;
      height: 20px;
      margin: 0;
      margin-right: 8px;
      padding: 0;
      outline: 0;
      border: 2px solid #9a9a9a;
      border-radius: 4px;
      background: transparent no-repeat center center;
      box-sizing: border-box;
      cursor: pointer;
      align-self: center;

      &:checked {
        background-color: #1f8aed;
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14'%3E%3Cpath fill='%23fff' d='M0,8 5,13 14,4 12,2 5,9 2,6z'/%3E%3C/svg%3E");
      }
    }

    span {
      margin-bottom: 0;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}
