@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400&display=swap");
@import "../common/mixins.scss";

.codeEditor {
  display: contents;

  :global(.cm-content) {
    user-select: text;
  }

  &.terminalCursor {
    :global {
      .cm-cursorLayer {
        animation: none !important;
        z-index: -1;
      }

      .cm-cursor {
        border: 0;
        margin: 0;
        width: 1ch;
        background: var(--cursor-color);
      }
    }
  }

  .terminalCursorMark {
    &,
    & * {
      color: var(--cursor-text-color) !important;
    }
  }

  .errorUnderline {
    background-repeat: repeat-x;
    background-position: bottom;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='3' viewBox='0 0 6 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.82843C0.511845 0.82843 1.02369 1.02369 1.41421 1.41422L1.58579 1.58579C2.36683 2.36684 3.63316 2.36684 4.41421 1.58579L4.58579 1.41422C4.97631 1.02369 5.48816 0.828431 6 0.828431' stroke='%23E91919'/%3E%3C/svg%3E%0A");
    padding-bottom: 1px;
  }

  .errorLineHighlight {
    background: rgba(255, 0, 0, 0.15);

    &:global(.cm-activeLine) {
      background: rgba(255, 0, 0, 0.2);
    }
  }

  .explainContextMark {
  }

  .explainContextSnippet {
    background: #e1e1e1;
    border-radius: 4px;
    padding: 2px 1px;
    font-style: italic;

    &:before {
      content: " := ";
    }

    @include darkTheme {
      background: #3a3a3a;
    }
  }
}
