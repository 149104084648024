@import "@edgedb/common/mixins.scss";

.dashboardWrapper,
.firstDashboardWrapper {
  flex-grow: 1;
  display: flex;
}

.dashboard,
.firstDashboard {
  flex-grow: 1;
  background-color: var(--app-panel-bg);
  border-radius: 8px;
  overflow: auto;
  @include hideScrollbar;

  @include breakpoint(mobile) {
    border-radius: 0;
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: #555;

  @include darkTheme {
    color: #aaa;
  }
}

.dashboardLayout,
.firstDashboardLayout {
  display: flex;
  flex-direction: column;
  align-items: center;

  --buttonBg: var(--app-accent-green);
  --buttonTextColour: #fff;

  .dbName {
    font-size: 28px;
    margin-top: 54px;
    font-family: Roboto Mono;
    font-weight: bold;
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
    }

    svg {
      fill: #999;
      transform: scale(1.8);
      display: inline-block;
      margin-right: 20px;
    }
  }
}

.dashboard {
  .buttons {
    margin: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    @include darkTheme {
      --buttonTextColour: #1d1d1d;
    }
  }

  .button.button {
    margin: 4px;
    width: 228px;

    & > div {
      padding: 24px 34px;
      border-radius: 4px;
      justify-content: center;
    }

    svg {
      fill: currentColor;
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }

    span {
      margin: unset;
    }
  }

  .docButtons {
    padding: 40px;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    color: --app-text-colour;

    a {
      padding: 20px;
      margin: 10px;
      min-width: 175px;

      text-decoration: none;
      outline: none;

      display: inline-flex;
      flex-direction: column;
      align-items: center;

      svg {
        color: #adadad;
        width: 24px;
      }

      span {
        display: inline-block;
        font-size: 18px;
        line-height: 32px;
        font-weight: bold;
        color: var(--app-text-colour);
      }

      &:hover {
        svg,
        span {
          color: var(--app-accent-green);
        }
      }
    }
  }
}

.stats {
  display: flex;
  gap: 20px 90px;
  margin: 0 56px;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }

  .statValue {
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
  }

  .statLabel {
    font-family: "Roboto Mono", monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    opacity: 0.55;
  }
}

.firstDashboard {
  .congrats {
    font-family: EdgeDB;
    font-weight: bold;
    font-size: 68px;
    max-width: 750px;
    text-align: center;
    line-height: 60px;
    margin-top: 50px;

    background: linear-gradient(90.06deg, #a088ff 1.59%, #e48da2 102.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .importData {
    margin-top: 50px;
    max-width: 400px;
    text-align: center;

    h3 {
      font-family: EdgeDB;
      font-size: 25px;
      margin: 0 0 20px 0;
    }

    p {
      font-size: 18px;
      margin: 20px 0;
    }

    & > div {
      margin: 30px 0;
      button {
        padding: 0;
      }
    }
  }
}
