@import "@edgedb/common/mixins.scss";

.tabs {
  display: flex;
  align-items: center;
  margin-left: 42px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 16px;
  padding-bottom: 16px;
  margin-bottom: -16px;
  @include hideScrollbar;

  @include isMobile {
    margin-left: 0;
  }
}

.tabSep {
  margin: 0 8px;
  flex-shrink: 0;
  color: var(--grey75);

  @include darkTheme {
    color: var(--grey30);
  }
}

.headerNavMenu {
  position: relative;
  flex-shrink: 0;
}

.headerNavButton {
  display: flex;
  align-items: center;
  height: 36px;
  font-size: 14px;
  color: var(--fontHeaderGrey);
  font-weight: 600;
  text-decoration: none;
  padding: 0 10px;
  border-radius: 4px;
  cursor: pointer;

  .title {
    margin: 0 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    flex-shrink: 0;
  }

  &:hover,
  &.active {
    color: var(--grey85);
    background-color: var(--grey25);

    @include darkTheme {
      color: var(--grey8);
      background-color: var(--grey70);
    }
  }
}

.dropdown {
  position: fixed;
  top: 56px;
  margin-left: calc(-220px * var(--dropdownOffset));
  display: flex;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-20px);
  transition: opacity 0.15s, transform 0.15s;
  background-color: var(--grey25);
  color: var(--grey85);
  border-radius: 6px;
  overflow: hidden;
  min-height: 240px;
  max-width: calc(100% - 24px);
  box-shadow: 0px 32px 20px -24px rgba(0, 0, 0, 0.12),
    0px 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 10;

  @include darkTheme {
    background-color: var(--grey70);
    color: var(--grey8);
  }

  @include isMobile {
    margin-left: 0;
    left: calc(50% - min(300px, 100% - 20px) / 2);
  }

  &.open {
    opacity: 1;
    pointer-events: all;
    transform: none;
  }

  .col {
    display: flex;
    flex-direction: column;
    width: 220px;
    padding: 2px 0;

    &:not(:last-child) {
      border-right: 2px solid var(--grey20);

      @include darkTheme {
        border-color: var(--grey50);
      }
    }

    @include isMobile {
      width: 300px;
    }
  }

  .header {
    padding: 6px 16px;
    padding-top: 12px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .item,
  .action {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    text-decoration: none;
    padding: 9px 18px;
    line-height: 24px;
    font-weight: 600;
    color: inherit;

    @include isMobile {
      padding: 12px 18px;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .item > span {
    flex-grow: 1;
  }

  .showCursor {
    .item.selected:after {
      content: "";
      pointer-events: none;
      position: absolute;
      inset: 1px;
      border: 2px solid var(--app-accent-green);
      border-radius: 4px;
    }
  }

  .actions {
    padding-top: 16px;
    margin-top: auto;
  }

  .action {
    margin: 6px 8px;
    padding: 7px 10px;
    justify-content: center;
    border: 1px solid var(--grey40);
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    @include darkTheme {
      border-color: var(--grey60);

      &:hover {
        background-color: rgba(255, 255, 255, 0.04);
      }
    }
  }

  .avatar {
    width: 22px;
    height: 22px;
    border-radius: 16px;
    background-size: contain;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .selected {
    background-color: var(--grey30);

    @include darkTheme {
      background-color: var(--grey60);
    }
  }

  .dbSpinner {
    margin: auto;
    color: #adadad;
  }

  .noItems {
    padding: 8px 16px;
    font-style: italic;
    opacity: 0.7;
  }

  .dbFetchError {
    padding: 8px 16px;
    font-style: italic;
    color: var(--accentRed);
  }
}
