@import "@edgedb/common/mixins.scss";

.textViewer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;

  :global(.cm-editor) {
    flex-grow: 1;
    min-height: 0;
  }
}

.scrollWrapper {
  flex-grow: 1;
  display: flex;
  min-height: 0;

  :global(.cm-scroller) {
    @include hideScrollbar;
  }
}
