@import "../../mixins.scss";

.toggleSwitch {
  cursor: pointer;
}

.track {
  display: flex;
  width: calc(var(--switchSize, 18px) * 2 - 4px);
  height: var(--switchSize, 18px);
  border-radius: var(--switchSize, 18px);
  background-color: var(--fullscreenOverlayBg);
  transition: background-color 0.2s;
}

.switch {
  width: calc(var(--switchSize, 18px) - 4px);
  height: calc(var(--switchSize, 18px) - 4px);
  border-radius: var(--switchSize, 18px);
  margin: 2px;
  background: #b3b3b3;
  transition: transform 0.2s, background-color 0.2s;

  @include darkTheme {
    background: #333;
  }

  .checked & {
    transform: translateX(calc(var(--switchSize, 18px) - 4px));
    background: var(--accentGreen);
  }
}
