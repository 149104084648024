@import "@edgedb/common/mixins.scss";

.modal {
  display: flex;
  flex-direction: column;
  color: var(--app-text-colour);
}

.codeBlock {
  background: var(--app-panel-bg);
  padding: 16px;
  border-radius: 8px;
  overflow: auto;

  pre {
    font-family: Roboto Mono, monospace;
    margin: 0;
    margin-left: 1em;
  }

  @include darkTheme {
    background: var(--app-bg);
  }
}

.statementName {
  font-family: Roboto Mono;
  font-size: 13px;
  opacity: 0.65;
}

.statementMessages {
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}

.errorMessage {
  background: rgba(222, 83, 83, 0.1);
  color: #de5353;
  padding: 7px 8px;
  border-radius: 4px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-size: 14px;

  svg {
    flex-shrink: 0;
    fill: currentColor;
    height: 15px;
    margin-right: 6px;
  }

  .errorName {
    font-weight: 500;
  }

  .errorDetails {
    color: #8d6363;

    @include darkTheme {
      color: #ba6b6b;
    }
  }
}

.codeBlockParam {
  display: inline-flex;
  background: #e0e0e0;
  line-height: 22px;
  height: 22px;
  border-radius: 12px;
  padding-left: 6px;
  overflow: hidden;

  .codeBlockParamValue {
    background: #fff;
    margin-left: 6px;
    padding: 0 6px;
  }

  @include darkTheme {
    background: #292929;

    .codeBlockParamValue {
      background: #414141;
    }
  }
}

.codeBlockParamInvalid {
  display: inline-flex;
  background: rgba(222, 83, 83, 0.1);
  color: #de5353;
  line-height: 22px;
  height: 22px;
  padding: 0 8px;
  border-radius: 12px;
  overflow: hidden;
}

.greenButton {
  --buttonBg: var(--app-accent-green);
  --buttonTextColour: #fff;
}

.redButton {
  --buttonTextColour: #de5353;
  margin-right: auto;
}
