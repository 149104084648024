@import "@edgedb/common/mixins.scss";

.databasePage {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  display: grid;
  padding: 0 16px 16px 0;
  grid-template-areas: "tabs session" "tabs content";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;

  .hide {
    opacity: 0;
    transition-delay: 0.2s;
  }

  &.mobile {
    grid-template-areas: "session" "content" "tabs";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    padding: 0;
  }
}

.sessionBar {
  grid-area: session;
}

.settingsTab {
  margin-top: auto;
  margin-bottom: 10px;
  display: none;
}

.tabbar {
  grid-area: tabs;
  margin-bottom: -16px;

  .mobile & {
    margin-bottom: 0;
  }
}

.tabContent {
  position: relative;
  grid-area: content;
  display: flex;
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
}

.card {
  background-color: var(--app-panel-bg);
  flex-grow: 1;
  border-radius: 8px;
}

.loadingState {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
}

.greenButton {
  --buttonBg: #49d8ad;
  --buttonTextColour: #fff;

  @include darkTheme {
    --buttonBg: #29b189;
    --buttonTextColour: #141414;
  }
}

.errorFallback {
  background: var(--app-panel-bg);
  flex-grow: 1;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;

  h2 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    color: #db5246;

    svg {
      fill: currentColor;
      margin-right: 12px;
      margin-bottom: -2px;
    }
  }

  .errorDetails {
    display: flex;
    font-style: italic;
    padding: 6px 0;

    button {
      margin-left: auto;
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      color: inherit;
      padding: 4px 12px;
      border-radius: 16px;
      cursor: pointer;

      @include darkTheme {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }

  pre {
    overflow: auto;
    background: var(--app-card-bg);
    padding: 16px;
    border-radius: 6px;
    margin: 0;
    user-select: text;
  }
}
