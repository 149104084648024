@import "@edgedb/common/mixins.scss";

.paramEditorPanel {
  background: #ededed;
  overflow: auto;
  max-height: 100%;
  min-height: 88px;

  @include hideScrollbar;

  @include darkTheme {
    background: #363636;
  }
}

.scrollWrapper {
  flex-shrink: 0;
  max-height: 80%;
}

.header {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #9b9b9b;
  padding: 13px 16px;
  position: sticky;
  top: 0;
  left: 0;
  background: #ededed;
  z-index: 1;

  @include darkTheme {
    background: #363636;
  }

  .dragHandle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 16px;
    cursor: ns-resize;

    &:after {
      content: "";
      position: absolute;
      width: 32px;
      height: 2px;
      border-radius: 2px;
      background: #c6c6c6;
      top: 7px;
      left: calc(50% - 16px);

      @include darkTheme {
        background: #656565;
      }
    }
  }
}

.paramsList {
  display: grid;
  grid-template-columns: auto auto 1fr;
  row-gap: 1.5rem;
  column-gap: 2rem;
  min-height: 0;
  padding: 0 16px 16px 16px;
}

.paramEditorItem {
  display: contents;
}

.paramDisabled {
  .paramDetails,
  .paramData {
    opacity: 0.35;
    pointer-events: none;
  }
}

.paramOptional {
  height: 32px;
  display: flex;
  align-items: center;
}

.paramDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  height: 32px;
}

.paramIdents {
  display: flex;

  font-family: Roboto Mono;
  font-size: 13px;
  line-height: 18px;
  color: #333;

  @include darkTheme {
    color: #c4c4c4;
  }
}

.paramType {
  color: rgb(51, 51, 51, 0.5);
  margin-right: 1rem;
  font-weight: 400;
  white-space: nowrap;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;

  @include darkTheme {
    color: rgba(196, 196, 196, 0.5);
  }
}

.paramOptionalLabel {
  font-size: 12px;
  line-height: 14px;

  color: rgb(51, 51, 51, 0.5);

  @include darkTheme {
    color: rgba(196, 196, 196, 0.5);
  }
}

.paramData {
  display: flex;
  justify-self: flex-start;
  padding-right: 24px;
}

.paramError {
  color: #eb4747;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.topLevelError {
  padding: 0 16px 16px 16px;
}
