@import "../../mixins.scss";

.tabbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 25px;
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  min-width: 0;
}

.tabs {
  display: flex;
  margin: 0 auto;
  gap: 25px;
  min-width: 0;

  &:before {
    display: block;
    content: "";
    width: 48px;
    flex-shrink: 1;
  }
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  text-decoration: none;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 6px;

    & > svg {
      fill: #999;
    }
  }

  .label {
    max-width: calc(100% + 16px);
    white-space: nowrap;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #808080;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &.selected {
    .icon {
      background: #f5f5f5;

      @include darkTheme {
        background: #242424;
      }
    }
  }
}

.menuButton {
  width: 48px;
  height: 60px;
  cursor: pointer;
  flex-shrink: 0;

  svg {
    fill: #808080;
  }

  @include darkTheme {
    svg {
      fill: #999;
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  background-color: var(--fullscreenOverlayBg);
  position: absolute;
  top: 0;
  z-index: 11;

  .menuPopup {
    overflow: auto;
    height: 100%;

    &.extraPaddingTop {
      padding-top: 20px;
    }

    .closeBtn {
      position: fixed;
      bottom: 16px;
      right: 16px;
    }
  }
}

.menuTabsList {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;

  &.showSep {
    border-top: 2px solid var(--grey85);
    border-bottom: 2px solid var(--grey85);

    @include darkTheme {
      border-color: var(--grey20);
    }
  }
}

.menuTabWrapper {
  text-decoration: none;

  .menuTab {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 500;
    color: var(--grey30);
    background: #f2f2f2;
    border-radius: 6px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    @include darkTheme {
      background: #242424;
      color: #b3b3b3;
    }

    @include breakpoint(mobile) {
      background: var(--grey90);
      color: var(--baseText);
      font-weight: 700;

      @include darkTheme {
        background: var(--grey18);
      }
    }
  }
}

.themeSwitcher {
  margin-bottom: 181px;
}
