@import "@edgedb/common/styles.scss";
@import "@edgedb/common/mixins.scss";

.app {
  position: relative;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
  font-size: 14px;
  line-height: 16px;
  background-color: var(--app-bg);
  color: var(--app-text-colour);
}

.theme {
  display: contents;

  --app-bg: #ffffff;
  --app-panel-bg: #f5f5f5;
  --app-card-bg: #e6e6e6;
  --app-card-outline: #c3c3c3;
  --app-text-colour: #4d4d4d;
  --app-accent-green: #0ccb93;
  --app-modal-overlay: rgba(0, 0, 0, 0.2);
  --app-modal-bg: #fff;
  --app-err-color: #cd9089;
  --app-bg-gray: #f5f5f5;
  --app-code: #4d4d4d;

  // Header
  --header-dropdown-bg: #fff;
  --header-dropdown-actions-bg: #f5f5f5;

  // Forms
  --form-field-bg: #ebebeb;
  --form-group-bg: #ebebeb;
  --form-field-text-colour: #4d4d4d;

  // Syntax highlighting
  --syntax-purple: #b044c2;
  --syntax-green: #1a8f66;
  --syntax-blue: #0075d2;
  --syntax-red: #e72525;
  --syntax-orange: #d78100;
  --syntax-comment: #7f7f7f;
  --syntax-mod: #838383;

  // Fonts
  --font-inactive-gray: #808080;
  --font-active-gray: #666666;

  // Buttons
  --btn-text-color: #333333;
  --btn-green: #0ccb93;
  --btn-text-hover: #fafafa;

  @include darkTheme {
    --app-bg: #141414;
    --app-panel-bg: #242424;
    --app-card-bg: #2e2e2e;
    --app-card-outline: #525252;
    --app-text-colour: #e7e7e7;
    --app-accent-green: #1b9873;
    --app-modal-overlay: rgba(0, 0, 0, 0.5);
    --app-modal-bg: #242424;
    --app-err-color: #a56c65;
    --app-bg-gray: #2d2d2d;
    --app-code: #c4c4c4;

    // Header
    --header-dropdown-bg: #2e2e2e;
    --header-dropdown-actions-bg: #141414;

    // Forms
    --form-field-bg: #141414;
    --form-group-bg: #2e2e2e;
    --form-field-text-colour: #f7f7f7;

    // Syntax highlighting
    --syntax-purple: #ac86f6;
    --syntax-green: #07a66d;
    --syntax-blue: #369ff2;
    --syntax-red: #f13f4a;
    --syntax-orange: #f4e470;
    --syntax-comment: #7f7f7f;
    --syntax-mod: #9f9f9f;

    // Fonts
    --font-inactive-gray: #7d7d7d;
    --font-active-gray: #a6a6a6;

    // Buttons
    --btn-green: #29b189;
    --btn-text-color: #adadad;
    --btn-text-hover: #141414;
  }
}
